export function decorateMessage(text, metadata) {
  if (metadata && metadata?.products && metadata?.paymentIntentId) {
    const { products } = metadata;

    let priceString = `Total (tax incl.): ${metadata.totalPrice}`;

    if (metadata.vat) {
      priceString = `VAT/GST: ${metadata.vat}\nTotal: ${metadata.totalPrice}`;
    }

    switch (metadata.source) {
      case 'supplements_order':
        return `Payment created for supplements:\n${products
          .map((p) => p.name)
          .join('\n')}\nExpress shipping: ${metadata.deliveryPrice}\n${priceString}`;
      case 'test_kits_order':
        return `Payment created for Test kits:\n${products
          .map((p) => p.name)
          .join('\n')}\nExpress shipping: ${metadata.deliveryPrice}\n${priceString}`;
      case 'devices_order':
        return `Payment created for devices:\n${products
          .map((p) => p.name)
          .join('\n')}\nExpress shipping: ${metadata.deliveryPrice}\n${priceString}`;
      case 'clients_list':
      default:
        return `Payment created for services:\n${products.map((p) => p.name).join('\n')}`;
    }
  }

  if (metadata && metadata?.messageType === 'schedule_consultation') {
    return 'Consultation booking link has been sent';
  }

  return text;
}
